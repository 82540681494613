<script>
import DefaultRetailerProduct from '/~/extensions/giftcards/components/product/retailer-product.vue'
import ProductHeader from '/~/extensions/giftcards/templates/bill-payments/components/product/product-header.vue'
import ProductTotal from '/~/extensions/giftcards/templates/bill-payments/components/product/product-total.vue'

export default {
  components: {
    ProductTotal,
    ProductHeader,
  },
  extends: DefaultRetailerProduct,
  computed: {
    isQuickBuyDisabled() {
      if (
        this.adding ||
        !this.currentItem ||
        (this.gift.visible && !this.gift.filled) ||
        this.isErrors
      ) {
        return true
      }

      if (this.giftQuantity && this.isVariableProductType) {
        return !this.isCorrectAmount
      }

      return !this.selectedPoint
    },
  },
}
</script>

<template>
  <validation-observer v-if="offer" ref="validationObserverRef" slim>
    <div>
      <product-flash
        v-if="isFlashCardType"
        :name="offer.attributes.name"
        :src="flashCardImage"
      />
      <div v-else>
        <product-header :offer="offer" :retailer="retailer" />
        <div v-if="html" class="mt-5 text-center" v-html="html" />
        <div v-else>
          <div
            v-if="isVariableProductType"
            class="my-[9px] flex flex-col items-start"
          >
            <div class="mx-auto w-full max-w-64 sm:ml-0">
              <div class="mb-[5px] text-center sm:text-left">
                Enter Amount
                <span v-if="variableProduct" data-test="amount-title">
                  (${{ minVariableValue }} - ${{ maxVariableValue }})
                </span>
              </div>

              <base-input
                ref="moneyInput"
                v-model="selectedValue"
                :validation="{
                  rules: `required|min_value:${minVariableValue}|max_value:${maxVariableValue}`,
                  name: 'Amount',
                  mode: 'aggressive',
                  vid: 'Amount',
                  unmasked: true,
                }"
                :mask="currencyMask"
                data-cy="amount"
                name="selectedValue"
                icon="plain/dollar-v2"
                :icon-size="18"
                nolabel
              />
            </div>
          </div>
          <div v-else class="my-3 px-4 text-eonx-neutral-800">
            <div class="m-auto flex flex-wrap justify-center sm:justify-start">
              <div
                v-for="product in options"
                :key="product.id"
                data-test="product-option"
                class="my-3 mr-2.5 flex flex-col items-center bg-white sm:mr-[15px]"
              >
                <v-popover
                  v-if="product.disabled"
                  offset="8"
                  trigger="hover click"
                >
                  <div
                    class="flex h-14 min-w-[72px] cursor-not-allowed items-center justify-center rounded border px-1.5 text-center font-bold text-eonx-neutral-600"
                    tabindex="0"
                  >
                    {{ product.label }}
                  </div>

                  <template #popover>
                    <div class="max-w-56 leading-tight">
                      <h4 class="text-2xl font-bold">
                        Sorry, currently out of stock!
                      </h4>
                      <p class="mt-5 text-base">
                        Please select another amount or try again later.
                      </p>
                    </div>
                  </template>
                </v-popover>
                <button
                  v-else
                  class="flex h-14 min-w-[72px] items-center justify-center rounded border border-solid px-[5px] text-center text-lg font-bold"
                  :class="[
                    selectedPoint === product.id
                      ? 'border-primary bg-primary-lightest text-primary opacity-75'
                      : 'bg-white',
                  ]"
                  @click="onSelect(product.id, product.price, product.disabled)"
                >
                  {{ product.label }}
                </button>
                <div class="mx-2.5">
                  {{ product.display_subtitle }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="offer" class="mb-6 px-5">
            <div
              class="mx-auto flex w-full max-w-96 items-center justify-between gap-x-1.5 rounded border bg-white p-3 text-xl sm:max-w-none sm:px-6"
            >
              <div data-cy="calculation-counter" class="h-7">
                <base-counter
                  v-model="giftQuantity"
                  :min="1"
                  :max="maxItemsQuantity"
                  :disabled="isCounterDisabled"
                  small
                />
              </div>
              <div class="flex items-center gap-1">
                <span class="text-base font-bold">Total</span>
                <product-total :amount="giftTotalAmount" />
              </div>
            </div>
          </div>
          <retailer-gift
            v-if="isGiftingEnabled"
            ref="retailerGift"
            v-model="recipient"
            :visible.sync="gift.visible"
            :filled.sync="gift.filled"
            :product-labels="productLabels"
            :physical="physical"
            :disabled="adding"
            :backend-errors="backendErrors"
            class="mb-2 mt-6 px-5 sm:px-0"
          />
          <product-add
            :is-add-button-show="false"
            :is-quick-buy-button-show="isQuickBuyButtonShow"
            :is-quick-buy-disabled="isQuickBuyDisabled"
            :is-add-button-disabled="isAddButtonDisabled"
            :adding="adding"
            :physical="physical"
            :buttons-fixed="buttonsFixed"
            @click-add-to-quick-buy="addToQuickBuy"
            @click-add-to-cart="onSubmitHandler"
          />
        </div>
      </div>
    </div>
  </validation-observer>
  <div
    v-else-if="!loading"
    class="mt-6 flex items-center justify-center text-lg sm:mt-0 sm:h-full"
  >
    Offer not available
  </div>
</template>
